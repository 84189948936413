<template>
  <Box>

    <SuccessRow :message="message" />
    <ErrorRow :error="error" />
    <!-- filters -->
    <div class="row rc-text-label"> <!-- row1 -->
      <div class="rc-box-col-10 rc-background-dark-thin">
        <Box> <!-- container1 -->
          
          <Row><!-- row1 -->
            <div class="rc-box-col-15">
              {{ catelog.get(catelogKeys.FILTERS.TITLE) }}
            </div>
          </Row>
          <Row>
            <div class="rc-box-col-2">
              {{ catelog.get(catelogKeys.FILTERS.STATE) }}
            </div>
            <div class="rc-box-col-2">
              <Button 
                v-on:click="setFilterState('all')" 
                :disabled="isFilterState('all')" > 
              {{catelog.get(catelogKeys.FILTERS.ALL) }} 
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button 
                v-on:click="setFilterState('active')" 
                :disabled="isFilterState('active')" >
                  {{ catelog.get(catelogKeys.FILTERS.ACTIVE) }} 
                </Button>
            </div>
            <div class="rc-box-col-2">
              <Button 
                v-on:click="setFilterState('inactive')" 
                :disabled="isFilterState('inactive')">
                {{ catelog.get(catelogKeys.FILTERS.INACTIVE) }}
              </Button>
            </div>
            <div class="rc-box-col-7">
            </div>
          </Row> <!-- /row1 -->
          
          <Row><!-- row2 -->
            <div class="rc-box-col-2">
              {{ catelog.get(catelogKeys.FILTERS.TYPE) }}
            </div>
            <div class="rc-box-col-2">
              <Button 
                v-on:click="setFilterType('all')" 
                :disabled="isFilterType('all')">
                {{ catelog.get(catelogKeys.FILTERS.ALL) }}
              </Button>
            </div>

            <div class="rc-box-col-2">
              <Button v-on:click="setFilterType('vehicle')" 
                :disabled="isFilterType('vehicle')"> 
                {{ catelog.get(catelogKeys.FILTERS.VEHICLES) }} 
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button v-on:click="setFilterType('trailer')" 
                :disabled="isFilterType('trailer')">
                {{ catelog.get(catelogKeys.FILTERS.TRAILERS) }}
              </Button>
            </div>
            
            <div class="rc-box-col-7" />
            
          </Row><!-- /row2 -->

          <Row> <!-- row 3 -->
            <div class="rc-box-col-2">
              Search
            </div>
            <div class="rc-box-col-5">
              <div class="container">
                <KeyValue 
                  title="search"
                  :clearValue="clear"
                  v-on:enter="startSearch"
                  v-on:value="searchValue"
                  />
              </div>
            </div>
            <div class="rc-box-col-1">
              <Button v-on:click="startSearch" 
                :disabled="isLoading"> Go </Button>
            </div>
    
            <div class="rc-box-col-1">
              <Button v-on:click="clearSearch" 
                :disabled="isLoading"> Clear </Button> 
            </div>
            <div class="rc-box-col-6" />
          </Row> <!-- /row3 -->
          
        </Box> <!-- container1 -->
      </div><!-- /box-10 -->

      <div class="rc-box-col-5 rc-background-dark-thin">
        <div class="container"> <!-- container1 -->
        
          <div class="row">
            <div class="rc-box-col-15">
              {{ catelog.get(catelogKeys.ACTIONS.TITLE) }}
            </div>
          </div>
          
          <div class="row">
            <div class="rc-box-col-4" />
            <div class="rc-box-col-7">
              <Button 
                v-on:click="add"
                :go="true"
                v-if="!isAdminList"
                :disabled="isLoading"> 
                {{ catelog.get(catelogKeys.ACTIONS.ADD_VEHICLE) }}
              </Button>
            </div>
            <div class="rc-box-col-4">
              <Button v-on:click="refresh()" 
                :disabled="isLoading"> 
                {{ catelog.get(catelogKeys.ACTIONS.REFRESH) }}
              </Button>
            </div>

          </div>
        </div> <!-- container1 -->
      </div>

    </div><!-- row1 -->
    <!-- /fitlers -->
    
    <!-- header row -->
    <div class="row rc-title-background rc-font-medium">
      <div class="rc-box-col-15">
        <VehicleRowHeader />
      </div>
    </div>
    <!-- header row -->

    <!-- Vehicles -->
    <Row :showBorder="true" v-if="!isLoading">
      <Column>
        <Box><!-- container -->
          <Row v-for="(vehicle, index) in vehicles" 
              :key="vehicle['@rid']" 
              :class="isEven(index) ? 'rowEven row' : 'rowOdd row'" 
              style="padding: 8px">
            
            <Column :width="15">
              <Box>
                <VehicleRow :vehicleData="vehicle" :index="index" :isAdmin="isAdmin"/>
                <VehicleMenuRow :vehicleData="vehicle" :index="index" :isAdmin="isAdmin" v-on:action="action" selected="none" />
              </Box>
            </Column>
              
          </Row>
        </Box> <!-- container -->
      </Column>
    </Row>
    <!-- /Vehicles -->

    <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }} </LoadingRow>

    <Row :showBorder="true" v-if="!isLoading && vehicles.length > 0" >
      <div class="rc-box-col-4" />
      <div class="rc-box-col-1">
        <Button v-if="canPreviousPage()" v-on:click="previousPage"> &lt; </Button>
      </div>
      
      <div class="rc-box-col-5 rc-text-centre rc-text-label">
        Showing {{ min }} to {{ max }}  of {{ total }}
      </div>
  
      <div class="rc-box-col-1">
        <Button v-if="canNextPage()" v-on:click="nextPage"> &gt; </Button>
      </div>
      <div class="rc-box-col-4" />
    </Row>

  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import StringUtils  from '@/utils/StringUtils.js';
import ConnectionUtils   from '@/utils/ConnectionUtils.js';
import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from "@/utils/ContentUtils.js";
import VehicleUtils from '@/views/portal/vehicles/Utils.js';
import MC from "@/domain/session/MC.js";

import VehicleRow       from '@/views/portal/vehicles/list/VehicleRow.vue';
import VehicleRowHeader from '@/views/portal/vehicles/list/VehicleRowHeader.vue';
import VehicleMenuRow   from '@/portals/customer/operator/views/vehicle/menu/Menu.vue';

import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from '@/components/row/SuccessRow.vue';
import KeyValue    from '@/components/input/KeyValue.vue';

import Catelog    from "@/domain/session/CanadianEnglish.js";
import VehicleMap from "@/domain/model/vehicle/VehicleMap.js";

import VehicleListFilter from "@/domain/model/vehicle/VehicleListFilter.js";

import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

import Button   from '@/portals/shared/library/button/Button.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

export default {
  name: 'portal-vehicles',
  components: {
    Box,Row,Column,
    VehicleRowHeader,
    VehicleRow,
    VehicleMenuRow,
    Button,
    ErrorRow,
    SuccessRow,
    KeyValue,
    LoadingRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    isAdminList: { type: Boolean, default: false },
  },
  data() {
    return {
      allVehicles: [],
      search: '',
      images: {},
      company: {},
      error: null,
      message: null,
      
      confirming: false,
      removingVehicle: null,
      
      confirmingRestore: false,
      restoringVehicle: null,
      showSessionTimeoutDialog: false,
      
      isLoading: false,
      loadingMessage: "",
      MC: new MC(),
      vehicles: [],
      stateFilter: 'active',
      typeFilter: 'all',
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,
      total: 0,
      
      pageSize: 20,
      pageNumber: 0,
      
      searchFor: null,
      clear: false,
    }
  },
  computed: {
    ...mapGetters([
        'auth_user', 
        'auth_client', 
        'auth_connected', 
        'vehicles_found', 
        'employees_found', 
        'types_found', 
        'makers_found', 
        'categorys_found', 
        'vehicles_list',
        'domain',
        'auth_socket_status',
        'signin_event',
      ]),
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.total < t ? this.total : t;
    },
    min: function() {
      if (this.total == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
    typeFilterComputed: function() {
      if (this.$route.query.type) {
        return this.$route.query.type;
      }
      return 'all';
    },
    pageNumberComputed: function() {
      if (this.$route.query.pageNumber) {
        return this.$route.query.pageNumber;
      }
      return 0;
    },
    stateFilterComputed: function() {
      if (this.$route.query.state) {
        return this.$route.query.state;
      }
      return 'active';
    },
    searchForComputed: function() {
      if (this.$route.query.text) {
        return this.$route.query.text;
      }
      return null;
    },
    pageSizeComputed: function() {
      if (this.$route.query.pageSize) {
        return this.$route.query.pageSize;
      }
      return this.pageSize;
    }
  },
  watch: {
    typeFilterComputed() {
      this.loadVehiclesByFilter();
    },
    pageNumberComputed() {
      this.loadVehiclesByFilter();
    },
    stateFilterComputed() {
      this.loadVehiclesByFilter();
    },
    searchForComputed() {
      this.loadVehiclesByFilter();
    },
    pageSizeComputed() {
      this.loadVehiclesByFilter();
    },
    
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                 ]),

    start: function() {
      this.isLoading = false;
      this.typeFilter = ContentUtils.getParam(this.$route.query.type, 'all');
      this.stateFilter = ContentUtils.getParam(this.$route.query.state,'active');
      this.searchFor = ContentUtils.getParam(this.$route.query.searchFor, null);
      this.pageNumber = ContentUtils.getParamInt(this.$route.query.pageNumber, 0);
      this.pageSize = ContentUtils.getParamInt(this.$route.query.pageSize, this.pageSize);
      this.sendInitialRequest();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    categoryName: function (vehicle) {
      if (!vehicle) {
        return "";
      }
      var categoryPointer = vehicle.VehicleCategory[ConstUtils.FIELDS.ID];
      var category = this.categorys_found.map[categoryPointer];
      if (!category) {
        category = this.categorys_found.map[vehicle.VehicleCategory];
      }
      if (!category) {
        return "";
      }
      var name = this.decode(category.name);
      return name;
    },
    
    decode: function (value) {
      return ContentUtils.unescape(value);
    },
    
    isEven: function (position) {
      return StringUtils.isEven(position)
    },

    add: function () {
      var company = this.domain.session().company();
      var params = {
        id: company.id(),
      }
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLE.ADD_V2,
        params: params,
      })
    },

    reset: function () {
      this.setFilterState('active');
      this.setFilterType('all');
      this.pageNumber = 0;
      this.showParams();
    },
    
    refresh: function () {
      this.loadVehiclesByFilter();
    },
    
    isFilterType(type) {
      return this.typeFilter === type;
    },
    
    isFilterState(state) {
      return this.stateFilter === state;
    },
    
    setFilterState(state) {
      this.stateFilter = state;
      this.pageNumber = 0;
      this.showParams();
    },
    
    setFilterType(type) {
      this.typeFilter = type;
      this.pageNumber = 0;
      this.showParams();
    },
    
    sendInitialRequest: function() {
      this.loadVehiclesByFilter();
    },
    
    loadVehiclesByFilter: function () {
      if (!this.domain || !this.domain.session()) {
        return;
      }
      if (!this.auth_connected) {
        if (!ConnectionUtils.isConnected(this, this.callback)) {
          ConnectionUtils.showSignIn(this);
        }
        return;
      } else {
        this.vehicles = [];
        this.isLoading = true;
        this.loadingMessage = this.MC.Vehicle.Status.Loading.value();
        var filter = new VehicleListFilter();
        filter
          .withPagination(this.pageSize, this.pageNumber)
          .withState(this.stateFilter)
          .withType(this.typeFilter)
          .withCompany(this.domain.session().company().id())
          .done();
        
        if (!StringUtils.isEmpty(this.searchFor)) {
          filter
            .withSearchText(this.searchFor)
            .done()
        }
        
        var event = this.domain.events().vehicles().list(filter);
        event.send(this.listener);
      }
    },
    
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        this.total = payload.count();
        var map = payload.valuesFor(VehicleMap.MODEL_NAME);
        if (map) {
          var list = Object.values(map.data);
          this.vehicles = list.sort(VehicleUtils.SortByName);
        }
      }
      this.isLoading = false;
      this.isRefreshing = false;
    },
    
    previousPage: function() {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.showParams();
      }
    },
    canPreviousPage: function() {
      return this.pageNumber > 0;
    },
    canNextPage: function() {
      var value = this.total > (this.pageSize * this.pageNumber) + this.pageSize;
      return value;
    },
    nextPage: function() {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.showParams();
      }
    },
    
    searchValue: function(value) {
      this.clear = false;
      this.searchFor = value['value'];
    },
    
    startSearch: function() {
      this.pageNumber = 0;
      this.showParams();
    },
    
    clearSearch: function() {
      this.searchFor = null;
      this.clear = true;
      this.showParams();
    },

    action: function(name) {
      if (name === "refresh") {
        this.refresh();
      }
    },
    
    showParams: function() {
      var params = {
          state: this.stateFilter,
          type: this.typeFilter,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        };
      
      if (this.searchFor) {
        params['searchFor'] = this.searchFor;
      }
      
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLES.LIST,
        query: params,
      }).catch(err => {
        this.domain.logger.info(err);
      });
      this.start();
    },
  },
}
</script>